@use '@angular/material' as mat;
@include mat.core();

// Define your custom color maps
$lake-blue: (
  50: #0072AE,
  100: #0072AE,
  200: #0072AE,
  300: #0072AE,
  400: #0072AE,
  500: #0072AE,
  600: #0072AE,
  700: #0072AE,
  800: #0072AE,
  900: #0072AE,
  A100: #0072AE,
  A200: #0072AE,
  A400: #0072AE,
  A700: #0072AE,
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF,
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #FFFFFF,
    A200: #FFFFFF,
    A400: #FFFFFF,
    A700: #FFFFFF,
  )
);

$forest-green: (
  50: #678E50,
  100: #678E50,
  200: #678E50,
  300: #678E50,
  400: #678E50,
  500: #678E50,
  600: #678E50,
  700: #678E50,
  800: #678E50,
  900: #678E50,
  A100: #678E50,
  A200: #678E50,
  A400: #678E50,
  A700: #678E50,
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF,
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #FFFFFF,
    A200: #FFFFFF,
    A400: #FFFFFF,
    A700: #FFFFFF,
  )
);

$sun-yellow: (
  50: #FFE472,
  100: #FFE472,
  200: #FFE472,
  300: #FFE472,
  400: #FFE472,
  500: #FFE472,
  600: #FFE472,
  700: #FFE472,
  800: #FFE472,
  900: #FFE472,
  A100: #FFE472,
  A200: #FFE472,
  A400: #FFE472,
  A700: #FFE472,
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF,
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #FFFFFF,
    A200: #FFFFFF,
    A400: #FFFFFF,
    A700: #FFFFFF,
  )
);

$cloud-grey: (
  50: #E1D9D4,
  100: #E1D9D4,
  200: #E1D9D4,
  300: #E1D9D4,
  400: #E1D9D4,
  500: #E1D9D4,
  600: #E1D9D4,
  700: #E1D9D4,
  800: #E1D9D4,
  900: #E1D9D4,
  A100: #E1D9D4,
  A200: #E1D9D4,
  A400: #E1D9D4,
  A700: #E1D9D4,
  contrast: (
    50: #FFFFFF,
    100: #FFFFFF,
    200: #FFFFFF,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #FFFFFF,
    A200: #FFFFFF,
    A400: #FFFFFF,
    A700: #FFFFFF,
  )
);

$app-primary: mat.m2-define-palette($forest-green, 900);
$app-accent: mat.m2-define-palette($forest-green, A400);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$default-theme: mat.m2-define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

@include mat.all-component-themes($default-theme);

// rgba version used for setting lower alphas
:root {
  --color-primary: #678E50;
  --color-primary-low-alpha: rgba(103, 142, 80, 0.3);
  --color-accent: #678E50;
  --color-accent-low-alpha: rgba(103, 142, 80, 0.3);
  --color-warn: #D32F2F;
  --color-warn-low-alpha: rgba(211, 47, 47, 0.6);
  --color-background: rgba(225, 217, 212, 0.1);
  --color-light-grey: #EEEEEE;
  --color-grey: #9E9E9E;
  --color-dark-grey: #757575;
}