html,
body {
    height: 100%;
    margin: 0;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.content {
    display: flex;
    flex: 1;
}

.pointer {
    cursor: pointer;
}

.text-faded {
    color: #8d8d8d;
}

.padding {
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
}

.padding-25 {
    margin-bottom: 25px;
    margin-left: 25px;
    margin-right: 25px;
}

.padding-top {
    padding-top: 15px;
}

.padding-top-5 {
    padding-top: 5px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}
  
.padding-bottom {
    padding-bottom: 15px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.margin-sides-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.margin-sides-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.margin-sides-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.margin-sides-25 {
    margin-left: 25px;
    margin-right: 25px;
}

.margin-sides-50 {
    margin-left: 50px;
    margin-right: 50px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-right-25 {
    margin-right: 25px;
}

.margin-right-50 {
    margin-right: 50px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-left-25 {
    margin-left: 25px;
}

.margin-left-50 {
    margin-left: 50px;
}

.margin-top-bottom-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.margin-top-bottom-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-top-bottom-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.padding-top-bottom-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-top-0 {
    margin-top: 0px;
}

.margin-top {
    margin-top: 15px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-25 {
    margin-top: 25px;
}

.margin-top-bottom-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.flex-container {
    display: flex;
    width: 100%;
}

.flex {
    display: flex;
}

.horizontal-flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.horizontal-flex-container-reverse {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.flex-container-stretch {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.flex-item-no-padding {
    flex: 1;
}

.flex-container-vertical-center {
    display: flex;
    align-items: center;
    width: 100%;
}

.flex-container-right-align {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.flex-container-space-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.flex-item {
    flex: 1;
    padding: 5px;
}

.flex-item-2 {
    flex: 2;
    padding: 5px;
}

.flex-item-3 {
    flex: 3;
    padding: 5px;
}

.flex-item-4 {
    flex: 4;
    padding: 5px;
}

.flex-item-5 {
    flex: 5;
    padding: 5px;
}

.flex-item-6 {
    flex: 6;
    padding: 5px;
}

.flex-item-align-end {
    flex: 1;
    padding: 5px;
    margin-left: auto;
}

.flex-item-align-end-2 {
    flex: 2;
    padding: 5px;
    margin-left: auto;
}

.flex-item-align-end-3 {
    flex: 3;
    padding: 5px;
    margin-left: auto;
}

.flex-item-align-end-4 {
    flex: 4;
    padding: 5px;
    margin-left: auto;
}

.grows-to-fill {
    height: 99%;
    margin-bottom: 15px;
    /* flex-grow: 1; (from admin if there are issues sharing) */
}

.flex-container-center {
    display:flex;
    justify-content: center;
    align-items: center;
    /* align items wasnt in admin */
    width: 100%;
}

.flex-container-column {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.flex-item-fixed-size {
    flex: 1;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 5px;
}

.flex-item-fixed-size-no-padding {
    flex: 1;
    flex-grow: 0;
    flex-shrink: 0;
}

.form-field {
    flex-grow: 1;
    padding-right: 5px;
}

.form-field-full {
    display: flex;
    flex: 1;
    padding: 5px;
}

.spacer {
    flex: 1 1 auto;
}

.spacer-small {
    flex: 1 1 auto;
    padding: 10px;
}

.spacer-medium {
    flex: 1 1 auto;
    padding: 20px;
}

.spacer-large {
    flex: 1 1 auto;
    padding: 30px;
}

.italics {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.light-bold {
    font-weight: 575;
}

.larger {
    font-size: larger;
}

.full-height {
    height: 100%;
}

.center {
    margin: auto;
    width: 25%;
    margin-top: 25px;
}

.icon-success {
    color: #9BBC88;
}

.icon-error {
    color: var(--color-warn);
}

.icon-warning {
    color: #F89406;
}

.icon-info {
    color: #2F96B4;
}

.block {
    display: block;
}

.radio-button {
    margin: 5px;
}

.previous-button {
    background-color: var(--color-light-grey);
}

/* Spinner to be shown on button click that has a web request behind it. */
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
}

.spinner-inline-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.spinner-inline {       
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite; 
}

.loading-spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.8);
}

/* Expandable table stuff */
tr.clickable-table-row:not(.example-expanded-row):hover {
    cursor: pointer;
    background: whitesmoke;
}

tr.clickable-table-row:not(.example-expanded-row):active {
    cursor: pointer;
    background: #efefef;
}

tr.expandable-table-row:not(.example-expanded-row):hover {
    cursor: pointer;
    background: whitesmoke;
}

tr.expandable-table-row:not(.example-expanded-row):active {
    cursor: pointer;
    background: #efefef;
}

.expandable-table-row td {
    border-bottom-width: 0;
    cursor: pointer;
}

table {
    width: 100%;
}

tr.expandable-detail-row {
    height: 0;
}

tr.example-entity-row:not(.example-expanded-row):hover {
    background: whitesmoke;
}

tr.example-entity-row:not(.example-expanded-row):active {
    background: #efefef;
}

.example-entity-row td {
    border-bottom-width: 0;
}

.expanded-entity-detail {
    overflow: hidden;
    display: flex;
}

.expanded-entity-information {
    width: 100%;
    padding: 8px;
    font-weight: lighter;
    margin: 8px 0;
    min-height: 104px;
}

.expanded-message-information {
    width: 100%;
    padding: 8px;
    font-weight: lighter;
    margin: 8px 0;
    min-height: 50px;
}

.table-container {
    position: relative;
    width: 100%;
}

.center-contents {
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.preserve-line-breaks {
    white-space: pre-wrap;
}

.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */
.toast-title {
    font-weight: bold;
}

.toast-message {
    word-wrap: break-word;
}

.toast-message a,
.toast-message label {
    color: #FFFFFF;
}

.toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #ffffff;
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
}

/*Additional properties for button version
iOS requires the button element instead of an anchor tag.
If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: relative;
    z-index: 999999;
    width: 100%;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .ngx-toastr {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    overflow: hidden;
    margin: 6px;
    padding: 15px 15px 15px 50px;
    width: 90%;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
}

.toast-success {
    background-color: #51A351;
}

.toast-error {
    background-color: var(--color-warn);
}

.toast-info {
    background-color: #2F96B4;
}

.toast-warning {
    background-color: #F89406;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

.mat-column-indicator {
    width: 15px;
    padding-right: 12px;
    text-align: center;
}

.link-style {
    color: var(--color-accent);
    text-decoration: underline;
    cursor: pointer;
}

.low-alpha-button mat-icon {
    color: var(--color-primary-contrast);
}

.card-header {
    color: var(--color-dark-grey);
    margin-left: 15px;
    font-size: 20px;
}

.bg-white {
    background-color: white;
}

.translate-down-5 {
    transform: translateY(5px);
}

.translate-down {
    transform: translateY(22px);
}

.translate-up-10 {
    transform: translateY(-10px);
}

.translate-up {
    transform: translateY(-22px);
}

.text-faded {
    color: #8d8d8d;
}

.back {
    position: relative;
    z-index: 1;
}

.front {
    position: relative;
    z-index: 2;
}

.animate-from-center {
    text-align: center;
    transform-origin: center;
}

.float-right {
    justify-content: space-between;
}

.no-opacity {
    opacity: 0;
    transition: opacity 4s ease-in-out; /* Animation properties */
}

.full-opacity {
    opacity: 1;
    transition: opacity 4s ease-in-out; /* Animation properties */
}

.no-margin {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

/* Notifications */
.notification-container {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Aligns notifications to the right */
    z-index: 1000; /* Ensures it's above other content */
}

.notification {
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    max-width: 320px;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-primary);
    color: #fff;
    padding: 8px;
    border-radius: 4px 4px 0 0;
    margin: -16px -16px 16px -16px;
    position: relative;
}

p {
    margin: 0;
}

.close-button {
    position: absolute;
    right: 0;
    top: 0;
    color: #fff; /* Adjust to contrast with header background */
}

.preserve-line-breaks {
    white-space: pre-wrap;
}

.primary {
    color: var(--color-primary);
}

[disabled] {
    position: relative;
}
/* End Notifications */

.validated-form-field-container {
    position: relative;
}

.min-height-200 {
    min-height: 200px;
}

.actioned-row td:first-child {
    border-left: 3px solid var(--color-primary);
}

/* remove increment control from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media print {
app-root > * { display: none; }
app-root app-print { display: block; }
}

/* Responsive Design */
@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
    }

    .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
    }

    .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.reghub-tooltip .mdc-tooltip__surface {
    font-size: 12.5px !important;
    background: #C6DBEA !important;
    color: black !important;
    border-radius: 10px !important;
    padding: 15px !important;
    text-align: center !important;
    z-index: 100000 !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2) !important;
    position: relative !important;
}